export enum UserStatus {
  Active = "active",
  Archived = "archived",
  AwaitingApproval = "awaitingApproval"
}

export const UserStatusLabel = new Map<UserStatus, string>([
  [UserStatus.Active, "Active"],
  [UserStatus.Archived, "Archived"],
  [UserStatus.AwaitingApproval, "Awaiting approval"]
]);

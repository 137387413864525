import axios, { AxiosResponse } from "axios";

import { UserDetail, UserCreateForm } from "../interfaces";
import { GridResponse } from "@homesusa/grid-table";
import { UserStatus } from "core/enums";

const apiUrl = `api/users`;

const createUser = async (userDetail: UserCreateForm): Promise<string> => {
  return axios
    .post<UserCreateForm, AxiosResponse<string>>(apiUrl, userDetail)
    .then((response) => response.data);
};

const getUsers = async (params: {
  top?: number;
  skip?: number;
  searchBy?: string;
  sortBy?: string;
  isForDownloading?: boolean;
  status?: UserStatus;
}): Promise<GridResponse<UserDetail>> => {
  const response = await axios.get(apiUrl, { params });
  const { data, total } = response.data;
  return { data: data as UserDetail[], total: Number(total) };
};

const getUserDetail = async (
  id: string,
  includeDeleted = false
): Promise<UserDetail> => {
  const response = await axios.get(
    `${apiUrl}/${id}?includeDeleted=${includeDeleted}`
  );
  return response.data as UserDetail;
};

const changePassword = async (
  id: string,
  oldPassword: string,
  newPassword: string
): Promise<void> => {
  const response = await axios.put(`${apiUrl}/${id}/change-password`, {
    oldPassword: oldPassword,
    newPassword: newPassword
  });
  return response.data;
};

const resetPasswordByAdministrator = async (
  id: string,
  newPassword: string
): Promise<void> => {
  const response = await axios.put(
    `${apiUrl}/${id}/reset-password-by-administrator`,
    {
      newPassword: newPassword
    }
  );
  return response.data;
};

const UpdateUser = async (userDetail: UserDetail): Promise<UserDetail> => {
  const { id, ...formattedService } = userDetail;
  const response = await axios.put(`${apiUrl}/${id}`, formattedService);
  return response.data;
};

const deleteUser = async (id: string): Promise<void> => {
  await axios.delete(`${apiUrl}/${id}`);
};

const activateUser = async (id: string): Promise<void> => {
  await axios.patch(`${apiUrl}/${id}/activate`);
};

export {
  getUsers,
  UpdateUser,
  getUserDetail,
  changePassword,
  createUser,
  resetPasswordByAdministrator,
  deleteUser,
  activateUser
};
